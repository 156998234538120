import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//Pages
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import Details from "../pages/Details";
import Index from "../pages/Index";
import Nearby from "../pages/Nearby";
import Login from "../pages/Login";
import Register from "../pages/Register";
import RestaurantDashboard from "../pages/RestaurantDashboard";
import { PrivateRoute } from "./PrivateRoute";

function AppRouter(props) {
	return (
		<Router>
			{props.children}
			<Switch>
				<Route exact path="/register">
					<Register />
				</Route>
				<Route exact path="/login">
					<Login />
				</Route>
				<PrivateRoute exact path="/super/dashboard" component={Dashboard} />
				<PrivateRoute exact path="/dashboard" component={RestaurantDashboard} />
				<Route exact path="/details">
					<Details />
				</Route>
				<Route exact path="/home">
					<Home />
				</Route>
				<Route exact path="/nearby">
					<Nearby />
				</Route>
				<Route exact path="/list">
					<Index />
				</Route>
				<Route exact path="/">
					<Home />
				</Route>
				<Route path="*">
					<div>
						<h1>Oooops I think we're lost</h1>
					</div>
				</Route>
			</Switch>
		</Router>
	);
}

export default AppRouter;
