import React, { useRef, useState } from "react";

import { InputHandler as IH } from "../helpers";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { StyledInput } from "./Inputs";

export function SearchBar() {
	const [query, setQuery] = useState();
	const formRef = useRef();

	const [selectedDate, setSelectedDate] = useState(new Date());

	const handleInputChange = (ev) => setQuery(IH(ev, query));
	const handleDateChange = (date) => {
		setSelectedDate(date);
	};

	const handleSearch = (event) => {
		event.preventDefault();
		console.log(query);
	};

	console.log(query);
	return (
		<form ref={formRef} onSubmit={handleSearch} className="form form--primary">
			<div className="input-group">
				<label>Time & Date</label>
				<div className="inputs">
					<KeyboardTimePicker
						margin="none"
						ampm={true}
						id="time-picker"
						value={selectedDate}
						onChange={handleDateChange}
						KeyboardButtonProps={{
							"aria-label": "change time",
						}}
						inputProps={{
							style: {
								height: "3.188em",
								outline: "none",
							},
						}}
					/>
					<div className="divider divider--vert"></div>
					<KeyboardDatePicker
						margin="none"
						id="date-picker-dialog"
						value={selectedDate}
						onChange={handleDateChange}
						disablePast
						KeyboardButtonProps={{
							"aria-label": "change date",
						}}
						inputProps={{
							style: {
								height: "3.188em",
							},
						}}
					/>
				</div>
			</div>
			<StyledInput
				name="query"
				label="Search Query"
				placeholder="Cuisine, restaurant or location"
				setValue={handleInputChange}
			/>
			<input className="btn btn--primary" type="submit" value="Search" />
		</form>
	);
}
