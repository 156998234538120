import React, { useState, useEffect } from "react";
import Page from "../components/Page";
import useSWR from "swr";
import Axios from "axios";
import RandomImage from "../components/RandomImage";

const API = "http://localhost:8080";

export const fetcher = (url) => fetch(url).then((r) => r.json());
function Dashboard() {
	const { data } = useSWR("/restaurant/list", fetcher);
	// const { data: pro } = useSWR("/auth/profile", fetcher);

	const [unvarified, setUnverified] = useState([]);
	const [wait, setWait] = useState(false);

	useEffect(() => {
		data && setUnverified(data.restaurants.filter((r) => !r.verified));
	}, [data]);

	return (
		<React.Fragment>
			<Page>
				<div className="container">
					<header>
						<p>Founder's Dashboard</p>
						<h2>Welcome back, Simo</h2>
					<p>
							{unvarified.length
								? "Some restaurants need to be verified"
								: "Nothing new here, go eat!"}
						</p>
					</header>

					<section>
						<div className="list list--grid">
							{unvarified.map((r) => (
								<figure className="card">
									<RandomImage key={Math.random()} className="img img--bg" />
									<figcaption>
										<h5>{r.name}</h5>
										<div>{r.phone}</div>
										<div>{r.emails && r.emails.map((e) => e).join("")}</div>
										<button
											disabled={wait}
											onClick={verifyRestaurant(r)}
											className="btn btn--primary"
										>
											{wait ? "Verifying..." : "Verify"}
										</button>
									</figcaption>
								</figure>
							))}
						</div>
					</section>
				</div>
			</Page>
		</React.Fragment>
	);

	function verifyRestaurant(restaurant) {
		return () => {
			setWait(true);
			Axios.post(
				`${API}/restaurant/verify`,
				{ _id: restaurant._id },
				{ headers: { "content-type": "application/json" } }
			)
				.then(() => alert("Restaurant Verified Successfully"))
				.catch(() => alert("Failed to verify"))
				.finally(() => setWait(false));
		};
	}
}

export default Dashboard;
