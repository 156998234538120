import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "../pages/Index";

function PageMobile(props) {
  return (
    <React.Fragment>
      <nav className="nav nav--top">
        <button>menu</button>
        <Link className="" to="/profile"></Link>
      </nav>
      <div className="container">{props.children}</div>
      <nav className="nav nav--bottom">
        <IconButton
          to="/list"
          label="List"
          icon="https://imgur.com/xP7Xpru.jpg"
        />
        <IconButton
          to="/nearby"
          label="Nearby"
          icon="https://image.flaticon.com/icons/svg/64/64113.svg"
        />
        <IconButton
          to="/reserved"
          label="Reserved"
          icon="https://image.flaticon.com/icons/svg/152/152456.svg"
        />
        <IconButton
          to="/saved"
          label="Saved"
          icon="https://image.flaticon.com/icons/svg/565/565639.svg"
        />
      </nav>
    </React.Fragment>
  );
}

export default PageMobile;
