import React, { useEffect } from "react";
import useSWR from "swr";
import { NavLink, BrowserRouter as Router, Route } from "react-router-dom";
import { useThemeDispatch } from "../context";
import { fetcher } from "../helpers";
import { SET_ERROR } from "../context/reducers";
import NaviLink from "../components/NaviLink";
import { Dashboard } from "./Dashboard.1";
import { Restaurants } from "./Restaurants";

function RestaurantDashboard() {
	const { data: restaurants, error: restError } = useSWR(
		"/restaurant/list-all",
		fetcher
	);
	const { data: bookings, error: bookError } = useSWR("/booking", fetcher);
	const { data: profile } = useSWR("/auth/profile", fetcher);

	console.log(bookings);

	const themeDispatch = useThemeDispatch();

	if (restError || bookError)
		themeDispatch({ type: SET_ERROR, payload: restError || bookError });

	return (
		<div className="dashy">
			<Dashboard data={{ restaurants, bookings, profile }} />
		</div>
	);
}

export default RestaurantDashboard;
