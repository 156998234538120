import React from "react";
import { useLocation } from "react-router-dom";

function Details() {
	const { state: r } = useLocation();
	console.log({ r });
	return (
		<div>
			<h1>{r.name}</h1>
		</div>
	);
}

export default Details;
