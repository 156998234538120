import React, { useEffect, useState } from "react";
import useSWR from "swr";

import RandomImage from "../components/RandomImage";
import { Link } from "react-router-dom";
import { fetcher } from "../helpers";

function ListRestaurants(props) {
	const { data } = useSWR("/restaurant/list", fetcher);
	const [restaurants, setRestaurants] = useState([]);

	useEffect(() => {
		console.log(data);
		if (!data) return;
		setRestaurants(data.restaurants.filter((r) => r.verified));
	}, [data]);

	return (
		<div className="container container--primary">
			<div>
				<h2>People like these!</h2>
				<p>These are some of the most visited places</p>
			</div>
			<div className="overflow-wrapper">
				<div className="list list--overflow">
					{restaurants.length ? (
						restaurants.map((r) => <CardLink data={r} />)
					) : (
						<div className="banner">Sorry! No restaurants yet!</div>
					)}
				</div>
			</div>
		</div>
	);
}

export function CardLink(props) {
	const { name, cuisine, seatsAvailable } = props.data;
	return (
		<Link to={{ pathname: "/", state: props.data }}>
			<figure className="card">
				<RandomImage className="img img--bg" />
				<figcaption className="card-details">
					<h3>{name}</h3>
					<p>{cuisine}</p>
					<p className="minor">{seatsAvailable} Seats Available</p>
				</figcaption>
			</figure>
		</Link>
	);
}
export default ListRestaurants;
