import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import useSWR from "swr";
import { CardLink } from "./ListRestaurants";

export const fetcher = (url) => fetch(url).then((r) => r.json());

function Index() {
  const { data } = useSWR("http://localhost:8080/restaurant/list", fetcher);

  const handleSubmit = (ev) => ev.preventDefault();
  useEffect(() => {
    console.log(data);
  }, [data]);
  if (!(data && data.restaurant.length)) return <p>Loading...</p>;

  return (
    <React.Fragment>
      <nav className="nav nav--top">
        <button>menu</button>
        <Link className="" to="/profile"></Link>
      </nav>
      <div className="container">
        <div className="greeting">
          <h2>Hey, Simo!</h2>
          <p>Let reserve you a table.</p>
        </div>
        <form onSubmit={handleSubmit} className="form form--inline">
          <input
            name="query"
            placeholder="Cuisine, restaurant or location"
            autoFocus
            type="text"
            className="input"
          />
          <button className="btn btn--icon">
            <img
              className="icon"
              style={{ fill: "#fff", stroke: "#fff" }}
              src="https://res.cloudinary.com/tumi/image/upload/v1597260676/icons/149267.svg"
              alt=""
            />
          </button>
        </form>

        <div>
          <ul className="list list--vert">
            {data && data.restaurant.length ? (
              data.restaurant.map((r) => <CardLink data={r} />)
            ) : (
              <p>Loading...</p>
            )}
          </ul>
        </div>
      </div>
      <nav className="nav nav--bottom">
        <IconButton to="/" label="List" icon="https://imgur.com/xP7Xpru.jpg" />
        <IconButton
          to="/nearby"
          label="Nearby"
          icon="https://image.flaticon.com/icons/svg/64/64113.svg"
        />
        <IconButton
          to="/reserved"
          label="Reserved"
          icon="https://image.flaticon.com/icons/svg/152/152456.svg"
        />
        <IconButton
          to="/saved"
          label="Saved"
          icon="https://image.flaticon.com/icons/svg/565/565639.svg"
        />
      </nav>
    </React.Fragment>
  );
}

export function IconButton({ to, label, icon }) {
  return (
    <NavLink to={to} className="nav__link" activeClassName="nav__link--active">
      <img src={icon} className="icon" alt="some icon" />
      <div className="label">{label}</div>
    </NavLink>
  );
}
export default Index;
