import { InputBase } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";

export default function ({ captureAddress }) {
	const [address, handleChange] = useState("");

	const handleSelect = (_address) => {
		captureAddress(_address);
		// geocodeByAddress(_address)
		// 	.then((results) => getLatLng(results[0]))
		// 	.then((latLng) => console.log("Success", latLng))
		// 	.catch((error) => console.error("Error", error));
	};

	useEffect(() => captureAddress(address), [address, captureAddress]);

	return (
		<PlacesAutocomplete
			value={address}
			onChange={handleChange}
			onSelect={handleSelect}
		>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
				<div className="input-group places-suggestor">
					<label htmlFor="places">Location</label>
					<InputBase
						{...getInputProps({
							placeholder: "Search Places ...",
							className: "location-search-input",
						})}
					/>
					<div className="autocomplete-dropdown-container">
						{loading && <div>Loading...</div>}
						{suggestions.map((suggestion) => {
							const className = suggestion.active
								? "suggestion-item--active"
								: "suggestion-item";
							// inline style for demonstration purpose
							return (
								<div
									{...getSuggestionItemProps(suggestion, {
										className,
									})}
								>
									<span>{suggestion.description}</span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</PlacesAutocomplete>
	);
}
