import React from "react";
import { Link } from "react-router-dom";

export function Nav() {
	return (
		<nav className="nav">
			<a href="/" className="logo">
				<div className="logo">
					<svg
						width="27"
						height="24"
						viewBox="0 0 27 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect width="27" height="24" fill="black" />
						<rect
							x="18"
							y="7"
							width="2"
							height="9"
							transform="rotate(90 18 7)"
							fill="white"
						/>
						<rect x="4" y="4" width="2" height="15" fill="white" />
						<rect
							x="13"
							y="11"
							width="2"
							height="9"
							transform="rotate(90 13 11)"
							fill="white"
						/>
						<rect x="11" y="11" width="2" height="8" fill="white" />
						<rect
							width="2"
							height="15"
							transform="matrix(-1 0 0 1 23 4)"
							fill="white"
						/>
						<rect
							width="2"
							height="9"
							transform="matrix(4.37114e-08 1 1 -4.37114e-08 14 11)"
							fill="white"
						/>
						<rect
							width="2"
							height="8"
							transform="matrix(-1 0 0 1 16 11)"
							fill="white"
						/>
					</svg>
				</div>
				<span className="logo__text">
					TABLE<strong>FOR</strong>
				</span>
			</a>
			<div className="nav__links">
				<Link to="/login" className="nav__link">
					Login
				</Link>
				<Link to="/" className="nav__link link--primary">
					Register
				</Link>
			</div>
		</nav>
	);
}
