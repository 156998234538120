import React, { useEffect, useState } from "react";
import useSWR from "swr";
import TabPager from "../components/TabPager";
import { fetcher } from "../helpers";
import FancyCard from "../components/FancyCard";
import BookingDetailsDialog from "../components/BookingDetailsDialog";
import NaviLink from "../components/NaviLink";

import Logo from "../assets/logo.svg";
import { Link, useHistory } from "react-router-dom";
import RandomImage from "../components/RandomImage";

export function Dashboard(props) {
	const { data: bookings, error: bookError, isValidating } = useSWR(
		"/booking",
		fetcher
	);
	const { data: restaurants, isValidating: restRefresh } = useSWR(
		"/restaurant/list",
		fetcher
	);

	const { data: user } = useSWR("/auth/profile", fetcher);

	const [list, set] = useState([]);
	const history = useHistory();
	const [filter, setFilter] = useState();
	const [filtered, setFiltered] = useState();

	useEffect(() => {
		if (!bookings) return;
		if (filter === "All" || !filter) return setFiltered();
		const _filtered = bookings.filter((b) => b.status === filter);
		return setFiltered(_filtered);
	}, [filter, bookings]);

	useEffect(() => {
		let output;
		if (!user || !restaurants || !bookings) return;
		if (user.isAdmin) {
			//User is super admin
			output = bookings;
		} else if (user.isMerchant) {
			// //User is merchant
			// output = _bookings.filter((b) =>
			// 	restaurants.some((r) => r.merchantId == user._id)
			// );

			//FIXME: Find something more performant

			const _restaurants = restaurants.restaurants.filter((r) =>
				r.emails.some((e) => e === user.email)
			);

			// return { bookings: _restaurants };

			output = bookings.filter((b) =>
				_restaurants.some((r) => r._id == b.restaurantId)
			);
		} else {
			//User is customer
			output = bookings.filter((b) => b.customerId == user._id);
		}
		set(output);
	}, [restRefresh, isValidating, user, bookings, restaurants]);

	return (
		<>
			<BookingDetailsDialog />
			<aside className="sidebar">
				<Link to="/" className="logo sidebar__logo">
					<img src={Logo} alt="table for logo" />
					<span className="text">
						TABLE<strong>FOR</strong>
					</span>
				</Link>
				<ul className="sidebar__links">
					<NaviLink to="/dashboard" label="Dashboard" />
					<NaviLink
						to="/dashboard"
						style={{
							cursor: "not-allowed",
						}}
						label="Restaurants"
					/>
					<NaviLink
						to="/dashboard"
						style={{
							cursor: "not-allowed",
						}}
						label="Menus"
					/>
					<NaviLink
						to="/dashboard"
						style={{
							cursor: "not-allowed",
						}}
						label="Customer"
					/>
					<NaviLink
						to="/dashboard"
						style={{
							cursor: "not-allowed",
						}}
						label="Setting"
					/>
				</ul>

				<div
					className="bg bg--light link btn--logout"
					onClick={() => {
						localStorage.clear();
						history.push("/");
					}}
				>
					<h3>logout</h3>
				</div>
			</aside>
			<main className="dashy__main">
				<nav className="dashy__top-bar">
					<div className="group">
						<h2 className="current-page">Reservations</h2>
						<div className="filter-toggle minor">filter</div>
					</div>
					<div className="group">
						<form className="inline-form">
							<input
								type="search"
								placeholder="Search"
								className="input input--dashy-search"
							/>
						</form>
						<div className="profile-thumb">
							{/* <img src="" alt="" className="img img--thumb" /> */}
							<RandomImage key={Math.random()} className="img img--thumb" />

							<div className="text">
								<div className="name">
									{props.data.profile && props.data.profile.fullname}
								</div>
								<div className="plan">Free tier</div>
							</div>
						</div>
					</div>
				</nav>

				<section className="dashy__list">
					<TabPager
						options={["All", "Confirmed", "Cancelled"]}
						onChange={setFilter}
					/>
					<ul className="card-list card-list--booking">
						{(filtered || (bookings && bookings.reverse().slice(0, 3))) &&
							(
								filtered ||
								(bookings && bookings.reverse().slice(0, 3))
							).map((b) => <FancyCard key={b._id} data={b} />)}
					</ul>
				</section>
				<section className="dashy__activities">
					<div>
						<h3>Activities</h3> <div className="time-filters"></div>
					</div>
					<div className="chart">
						<div className="lame-banner">Comming soon...</div>
					</div>
				</section>
				<section className="dashy__calender">
					<h3>Calender</h3>
					<div className="chart">
						<div className="lame-banner">Comming soon...</div>
					</div>
				</section>
			</main>
		</>
	);
}
