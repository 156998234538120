import React, { useEffect } from "react";
import Scrollbar from "smooth-scrollbar";

import { Nav } from "./Nav";

function Page(props) {
  useEffect(() => {
    const wrapper = document.querySelector(".wrapper");
    wrapper && Scrollbar.init(wrapper);
  }, []);
  return (
    <div className="wrapper">
      <div className="page">
        <Nav {...props} />
        {props.children}
      </div>
    </div>
  );
}

export default Page;
