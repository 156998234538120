import React, { createContext } from "react";

import { BookingReducer } from "./reducers";

const BookingStateContext = createContext();
const BookingDispatchContext = createContext();

function BookingProvider({ children }) {
	const [state, dispatch] = React.useReducer(BookingReducer, { current: null });
	return (
		<BookingStateContext.Provider value={state}>
			<BookingDispatchContext.Provider value={dispatch}>
				{children}
			</BookingDispatchContext.Provider>
		</BookingStateContext.Provider>
	);
}

function useBookingState() {
	const context = React.useContext(BookingStateContext);
	if (context === undefined) {
		throw new Error("useBookingState must be within BookingProvider");
	}
	return context;
}
function useBookingDispatch() {
	const context = React.useContext(BookingDispatchContext);
	if (context === undefined) {
		throw new Error("useBookingDispatch must be within a BookingProvider");
	}
	return context;
}

export { BookingProvider, useBookingState, useBookingDispatch };
