import React, { useRef, useState } from "react";
import { StyledInput } from "../components/Inputs";
import { Button } from "../components/buttons";
import { PostData } from "../helpers";
import PlacesSuggestor from "../components/PlacesSuggestor";

function AddRestaurant() {
	const formRef = useRef();
	const [data, setData] = useState();
	const [wait, setWait] = useState(false);

	const handleChange = (ev) =>
		setData({
			...data,
			[ev.target.name]: ev.target.value,
		});
	const [stage, setStage] = useState(1);
	const [address, captureAddress] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.clear();

		if (!data) return;
		setWait(true);

		await PostData("/restaurant/add", {
			...data,
			baseLocation: {
				address: address || "Durban, KZN, South Africa",
			},
			numLocations: 1,
		})
			.then(() =>
				alert("Restaurant registered succefully. Please check your emails.")
			)
			.catch((err) => alert(JSON.stringify(err)))
			.finally(() => setWait(false));
	};

	return (
		<div className="container container--primary col">
			<div className="container__body">
				<h1>Want your restaurant to be featured too?</h1>
				<p className="body-text">
					Please enter your just your details here and we'll get back to you to
					complete the process.
				</p>
			</div>
			<section
				className={`form--auth ${stage === 2 ? "at-stage-2" : "at-stage-1"}`}
				ref={formRef}
			>
				<div className="stages">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							if (data.fullname && data.email && data.phone) {
								setStage(2);
							}
						}}
						className="stage stage--1"
					>
						<StyledInput
							setValue={setData}
							onChange={handleChange}
							type="text"
							name="fullname"
							label="Fullname"
							id="fullname"
							placeholder="e.g Happy Human"
							autoCapitalize
							required
						/>
						<StyledInput
							setValue={setData}
							onChange={handleChange}
							type="email"
							name="email"
							label="Email"
							id="email"
							placeholder="e.g human@earth.com"
							required
						/>
						<StyledInput
							setValue={setData}
							onChange={handleChange}
							type="tel"
							name="phone"
							label="Phone Number"
							id="phone"
							placeholder="e.g 081 234 5678"
							required
						/>
						<button className="btn btn--primary">One more step</button>
					</form>
					<form onSubmit={handleSubmit} className="stage stage--2">
						<StyledInput
							setValue={setData}
							onChange={handleChange}
							type="text"
							name="name"
							label="Restaurant Name"
							id="phone"
							placeholder="e.g Fancy Restaurant"
							required
						/>
						<StyledInput
							setValue={setData}
							onChange={handleChange}
							type="text"
							name="registration"
							label="Registration Number"
							id="rgistration"
							placeholder="e.g --- --- ---"
							required
						/>
						{/* <StyledInput
							setValue={setData}
							onChange={handleChange}
							type="text"
							name="registration"
							label="Registration Number"
							id="rgistration"
							placeholder="e.g --- --- ---"
							required
						/> */}
						<PlacesSuggestor captureAddress={captureAddress} />
						<div className="flex">
							<Button label="Register Restaurant" type="submit" wait={wait} />
							<div
								onClick={() => {
									setStage(1);
								}}
								className="btn"
							>
								Go back
							</div>
						</div>
					</form>
				</div>
			</section>
		</div>
	);
}

export default AddRestaurant;
