import React, { useState } from "react";
import PageMobile from "../components/Page.Mobile";
import { useEffect } from "react";

function Nearby() {
	const [coords, setCoords] = useState();

	useEffect(() => {
		//Get coordinates
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setCoords({ ...position.coords });
				console.log(position);
			});
		} else {
			alert("Geolocation is not supported by this browser.");
		}
	}, []);

	return (
		<PageMobile>
			<div className="map" id="map"></div>
			<div className="head">
				<h4>Nearby Places</h4>
				{coords}
			</div>
		</PageMobile>
	);
}

export default Nearby;
