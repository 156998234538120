import React, { useState } from "react";
import { useBookingDispatch, useBookingState } from "../context";
import { SET_CURRENT } from "../context/reducers";
import { PostData } from "../helpers";
import { DateTime } from "luxon";

export default function BookingDetailsDialog() {
	const bookingState = useBookingState();
	const bookingDispatch = useBookingDispatch();

	const [counter, setCounter] = useState(1);

	const handleConfirm = () => {
		PostData("/booking/cancel", { bookingId: bookingState.current._id });
	};
	const handleCancelation = () => {
		PostData("/booking/cancel", { bookingId: bookingState.current._id });
	};

	return (
		<dialog open={!!bookingState.current} className="dialog">
			<div className="dialog__inner">
				<button
					onClick={() => bookingDispatch({ type: SET_CURRENT, payload: null })}
					className="btn btn--close"
				>
					Close
				</button>
				<h3>Booking Details</h3>
				<br />
				<p>
					TABLE<strong>FOR</strong>{" "}
					{bookingState.current && bookingState.current.seats}
				</p>
				<p>Other Customer booking details will go here!</p>
				<br />
				<br />
				<br />
				<div className="flex">
					<button onClick={handleConfirm} className="btn btn--primary">
						Confirm Reservation
					</button>
					<button onClick={handleCancelation} className="btn">
						Cancel Reservation
					</button>
				</div>
			</div>
		</dialog>
	);
}
