import React from "react";

export function Form({ onSubmit, ...props }) {
  const handleSubmit = (ev) => {
    ev.preventDefault();
    onSubmit && onSubmit();
  };
  return (
    <form onSubmit={handleSubmit} {...props}>
      {props.children}
    </form>
  );
}
