import React from "react";
import { SWRConfig } from "swr";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnUtils from "@date-io/moment";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "styled-components";
import { AuthProvider, BookingProvider, ThemeProvider } from "../context";

export default function AppProviders(props) {
	const swrOptions = {
		refreshInterval: 100000,
		fetcher: (...args) => fetch(...args).then((res) => res.json()),
	};

	const theme = createMuiTheme({
		palette: {
			primary: {
				main: "#000",
			},
			secondary: {
				main: "#fff",
			},
		},
	});

	return (
		<SWRConfig value={swrOptions}>
			<AuthProvider>
				<BookingProvider>
					<ThemeProvider theme={theme}>
						<MuiThemeProvider theme={theme}>
							<MuiPickersUtilsProvider utils={DateFnUtils}>
								{props.children}
							</MuiPickersUtilsProvider>
						</MuiThemeProvider>
					</ThemeProvider>
				</BookingProvider>
			</AuthProvider>
		</SWRConfig>
	);
}
