import React from "react";
import { NavLink } from "react-router-dom";

export default function NaviLink({ label, to, ...props }) {
	return (
		<NavLink
			to={to}
			className="sidebar__link"
			activeClassName="sidebar__link sidebar__link--active"
			{...props}
		>
			<span className="icon"></span>
			<span className="text">{label}</span>
		</NavLink>
	);
}
