import React from "react";

export default function TabPager({ options, onChange }) {
	return (
		<div className="tab-pager">
			{options.map((option, index) => (
				<>
					<input
						type="radio"
						defaultChecked={index === 0}
						name="filter"
						id={option}
						onChange={(ev) => onChange(ev.target.value)}
						value={option}
					/>
					<label for={option} className="tab-toggle">
						{option}
					</label>
				</>
			))}
		</div>
	);
}
