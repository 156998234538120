import React from "react";
import { Link } from "react-router-dom";
import { useBookingDispatch } from "../context";
import { SET_CURRENT } from "../context/reducers";

export default function FancyCard({ data, openDialog }) {
	const bookingDispatch = useBookingDispatch();
	return (
		<figure className="fancy-card">
			<p className="minor">
				TABLE<strong>FOR?</strong>
			</p>
			<div className="medium">
				<strong>{data.seats}</strong> People
			</div>
			<p>at {new Date(data.date).toDateString()}</p>
			<p>
				{data.items.length} {data.items.length > 1 ? "Meals" : "Meal"} Requested
			</p>
			<button
				onClick={() => bookingDispatch({ type: SET_CURRENT, payload: data })}
			>
				View Details
			</button>
		</figure>
	);
}
