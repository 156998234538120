import React from "react";
import { InputBase } from "@material-ui/core";

export function StyledInput({
  label,
  name,
  placeholder,
  setValue,
  state,
  ...props
}) {
  const handleChange = (ev) =>
    setValue({
      ...state,
      [ev.target.name]: ev.target.value,
    });

  return (
    <div className="input-group">
      <label htmlFor="search">{label}</label>
      <div className="inputs">
        <InputBase
          onChange={handleChange}
          name={name}
          placeholder={placeholder}
          style={{
            width: "100%",
            height: "100%",
            outline: "none",
            padding: "16px",
            borderRadius: "4px",
          }}
          {...props}
        />

        {!!props.helperText && (
          <div className="helper-text">{props.helperText}</div>
        )}
      </div>
    </div>
  );
}
