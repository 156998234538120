import React from "react";
import Page from "../components/Page";
import { Form } from "../components";
import { StyledInput } from "../components/Inputs";
import { useState } from "react";
import { Button } from "../components/buttons";
import { useEffect } from "react";
import { PostData } from "../helpers";

function Register() {
	const [data, setData] = useState();
	const [wait, setWait] = useState(false);

	useEffect(() => console.log({ env: process.env.NODE_ENV }), []);

	const handleChange = (ev) =>
		setData({
			...data,
			[ev.target.name]: ev.target.value,
		});

	const attempRegister = async () => {
		setWait(true);
		await PostData("/register", data)
			.then((res) => console.log(res.data))
			.catch((err) => alert(JSON.stringify(err)));
	};
	return (
		<Page>
			<Form onSubmit={attempRegister}>
				<StyledInput
					onChange={handleChange}
					type="text"
					name="fullname"
					label="Fullname"
					id="fullname"
					placeholder="e.g Happy Human"
					autoFocus
					autoCapitalize
					required
				/>
				<StyledInput
					onChange={handleChange}
					type="email"
					name="email"
					label="Email Address"
					id="email"
					placeholder="e.g Happy@Human.com"
					autoFocus
					autoCapitalize
					required
				/>
				<StyledInput
					onChange={handleChange}
					type="tel"
					name="phone"
					label="Phone"
					id="phone"
					placeholder="e.g 081 969 9034"
					autoFocus
					autoCapitalize
					required
				/>
				<StyledInput
					onChange={handleChange}
					type="password"
					name="password"
					label="Password"
					id="password"
					autoFocus
					required
				/>
				<StyledInput
					onChange={handleChange}
					type="password"
					name="confirm-password"
					label="Confirm Password"
					id="password"
					autoFocus
					required
				/>
				<Button label="Submit" type="submit" wait={wait} />
			</Form>
		</Page>
	);
}

export default Register;
