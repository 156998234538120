import React from "react";
import AddRestaurant from "./AddRestaurant";
import ListRestaurants from "./ListRestaurants";
import { Nav, SearchBar } from "../components";
import RandomImage from "../components/RandomImage";

export default function Home(props) {
	const handleSearch = (query) => {
		console.log({ query });
	};
	return (
		<div className="page">
			<Nav {...props} />
			<img
				src="https://images.unsplash.com/photo-1508006728353-6ecef00dcbb8?ixlib=rb-1.2.1&auto=format&fit=crop&w=751&q=80"
				alt="Restaurant"
				className="img img--bg img--header"
			/>
			<header className="container container--header">
				{/* <img className="img img--bg" src="" alt="" /> */}
				<div className="hero">
					<h1 className="head hero__head">Reserve a seat, Easily!</h1>
					<p className="body-text hero__body">
						Relax... we got you. Just fill in these field and you’re set to go!
					</p>
				</div>
			</header>
			<div className="container container--overlap">
				<SearchBar onSearch={handleSearch} />
			</div>
			<ListRestaurants />
			<AddRestaurant />
		</div>
	);
}
