import React, { useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { StyledInput } from "../components/Inputs";
import { Nav } from "../components/Nav";
import Page from "../components/Page";
import { PostData } from "../helpers";

function Login() {
	const formRef = useRef();
	const [data, setData] = useState();
	const [wait, setWait] = useState(false);

	const location = useLocation();
	const history = useHistory();

	const redirect = location.redirect;
	const from = location.from;

	const handleChange = (ev) =>
		setData({
			...data,
			[ev.target.name]: ev.target.value,
		});

	console.log({ from, redirect });
	const handleSubmit = async (e) => {
		e.preventDefault();
		console.clear();

		if (!data) return;
		setWait(true);

		await PostData("/auth/login", data)
			.then((res) => {
				setWait(false);
				if (res.status === 200) {
					localStorage.setItem("session", JSON.stringify(res));
					history.push(from || "/dashboard");
				}
			})
			.catch((err) => {
				console.log({ err });
				// alert(err.message)
			})
			.finally(() => setWait(false));
	};

	return (
		<main className="page page--auth">
			<img
				src="https://images.unsplash.com/photo-1508006728353-6ecef00dcbb8?ixlib=rb-1.2.1&auto=format&fit=crop&w=751&q=80"
				alt="Restaurant"
				className="img auth--bg"
			/>
			<h1>
				{redirect ? "Sorry but you need to be logged in to do that" : null}
			</h1>
			<form onSubmit={handleSubmit} className="form--auth" ref={formRef}>
				<h1>Login</h1>
				<p>Great to see you again!</p>
				<div className="stage">
					<StyledInput
						setValue={setData}
						onChange={handleChange}
						type="email"
						name="email"
						label="Email"
						id="email"
						placeholder="e.g human@earth.com"
						required
					/>
					<StyledInput
						setValue={setData}
						onChange={handleChange}
						type="password"
						name="password"
						label="Password"
						id="password"
						placeholder="***********"
						required
					/>
					<button disabled={wait} className="btn btn--primary">
						{wait ? "Logging in..." : "Login"}
					</button>
					<Link to="/" className="btn">
						Go Home
					</Link>
				</div>
			</form>
		</main>
	);
}

export default Login;
