import React from "react";
import "./App.css";

import AppProviders from "./components/AppProviders";
import AppRouter from "./routes/AppRouter";

function App() {
	//TODO: Move this elsewhere
	return (
		<AppProviders>
			<AppRouter />
		</AppProviders>
	);
}

export default App;
